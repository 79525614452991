<template>
    <v-card flat class="mb-7 w-100">
        <v-card-text class="pa-5">
            <span class="lstick"></span>
            <h3 class="title font-weight-regular">Comentarios Recientes</h3>
        </v-card-text>
        <v-virtual-scroll v-if="!loading" :items="comments" :item-height="70" height="400">
            <template v-slot:default="{ index, item }">
                <ItemComment :item="item" :key="index" :index="index" :count="comments.length" @reload="getComments" />
            </template>
        </v-virtual-scroll>
        <v-virtual-scroll v-else :items="[1,2,3]" :item-height="70" height="400">
            <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
            <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
            <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
        </v-virtual-scroll>
    </v-card>
</template>

<script>
    import dashboardService from '@/providers/DashboardService';
    import ItemComment from "@/components/dashboards/recent-comments/ItemComment.vue";
    
    export default {
        name: "TheRecentComments",
        components: {ItemComment},
        props: {
            comment: Object,
        },
        data: () => ({
            comments: [],
            loading: true,
        }),
        methods: {
            getComments() {
                this.loading = true;
                dashboardService.getRecientComments().then(record => {
                    this.comments = record.value;
                }).catch(err=>console.log(err)).finally(()=>{
                    this.loading = false;
                });
            },
        },
        mounted() {
            this.comments = [];
            this.getComments();
        }
    };
</script>